import { useState } from "react";
import { createRoot } from "react-dom/client";
const App = () => {
    const [state, setState] = useState(0);
    const [modal, setModal] = useState(-1);

    // Change style of navbar on scroll
    window.onscroll = function () { myFunction() };
    function myFunction() {
        const navbar = document.getElementById("myNavbar");
        if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
            navbar.className = "w3-bar" + " w3-card" + " w3-animate-top" + " w3-white";
        } else {
            navbar.className = navbar.className.replace(" w3-card w3-animate-top w3-white", "");
        }
    }

    // Used to toggle the menu on small screens when clicking on the menu button
    function toggleFunction() {
        var x = document.getElementById("navDemo");
        if (x.className.indexOf("w3-show") == -1) {
            x.className += " w3-show";
        } else {
            x.className = x.className.replace(" w3-show", "");
        }
    }
    return (
        <>
            <div className="w3-top">
                <div className="w3-bar" id="myNavbar">
                    <a className="w3-bar-item w3-button w3-hover-black w3-hide-medium w3-hide-large w3-right" onClick={toggleFunction} title="Toggle Navigation Menu">
                        <i className="fa fa-bars"></i>
                    </a>
                    <a href="#home" className="w3-bar-item w3-button">HOME</a>
                    <a href="#about" className="w3-bar-item w3-button w3-hide-small"><i className="fa fa-user"></i> ABOUT</a>
                    <a href="#portfolio" className="w3-bar-item w3-button w3-hide-small"><i className="fa fa-th"></i> PORTFOLIO</a>
                    <a href="#contact" className="w3-bar-item w3-button w3-hide-small"><i className="fa fa-envelope"></i> CONTACT</a>
                    <a href="#" className="w3-bar-item w3-button w3-hide-small w3-right w3-hover-red">
                        <i className="fa fa-search"></i>
                    </a>
                </div>
                <div id="navDemo" className="w3-bar-block w3-white w3-hide w3-hide-large w3-hide-medium">
                    <a href="#about" className="w3-bar-item w3-button" onClick={toggleFunction}>ABOUT</a>
                    <a href="#portfolio" className="w3-bar-item w3-button" onClick={toggleFunction}>PORTFOLIO</a>
                    <a href="#contact" className="w3-bar-item w3-button" onClick={toggleFunction}>CONTACT</a>
                    <a href="#" className="w3-bar-item w3-button">SEARCH</a>
                </div>
            </div>
            <div className="bgimg-1 w3-display-container w3-opacity-min" id="home">
                <div className="w3-display-middle" style={{ whiteSpace: 'nowrap' }}>
                    <span className="w3-center w3-padding-large w3-black w3-xlarge w3-wide w3-animate-opacity">LAKSH <span className="w3-hide-small">ENTERPRISES</span></span>
                </div>
            </div>
            <div className="w3-content w3-container w3-padding-64" id="about">
                <h3 className="w3-center">ABOUT ME</h3>
                <p className="w3-center"><em>Surgical Instruments</em></p>
                <p>
                    We believe in abiding by the industrial standards and supplying high-quality air compressor accessories that will impress our clients. Our philosophy has been to understand diverse client needs and offer them the products they need without any hassle.Quality is of immense importance for us and we take every possible measure to ensure that our clients receive nothing but the best from our end. Made of high-quality ingredients, the products in our range are unlikely to leave any room for reproach.
                </p><div className="w3-row">
                    <div className="w3-col m6 w3-center w3-padding-large">
                        <p><b><i className="fa fa-user w3-margin-right"></i>Hemant Verma</b></p><br />
                        <img src="/w3images/avatar_hat.jpeg" className="w3-round w3-image w3-opacity w3-hover-opacity-off" alt="Photo of Me" width="500" height="333" />
                    </div>
                    <div className="w3-col m6 w3-hide-small w3-padding-large">
                        <p>Based in Navi Mumbai, Laksh Enterprises is one of the leading wholesale supplier and trader of superior quality surgical instruments. We established our company in 2007 and have been delivering diverse clients with products that are both durable and application specified. Our range of products includes scissors, clamps, needle holders, forceps and so on from renowned brands such as Dazzle.

                            We take special care to ensure the quality of our products are at par with the client’s expectations. Our organisation uses a team of experts to come up with the best products to ensure no gaps on the quality parameter. Each of products are passed through a series of quality checks before being dispatched.

                            For any kind of query, please feel free to get in touch with us. We hope that you will grant us a chance to serve you and have great business experience with us.

                        </p></div>
                </div>
                <p className="w3-large w3-center w3-padding-16">Im really good at:</p>
                <p className="w3-wide"><i className="fa fa-check"></i>Quality</p>
                <div className="w3-light-grey">
                    <div className="w3-container w3-padding-small w3-dark-grey w3-center" style={{ width: '100%' }}>100%</div>
                </div>
                <p className="w3-wide"><i className="fa fa-heart"></i>Reliability</p>
                <div className="w3-light-grey">
                    <div className="w3-container w3-padding-small w3-dark-grey w3-center" style={{ width: '100%' }}>100%</div>
                </div>
                <p className="w3-wide"><i className="fa fa-truck"></i>Shipping</p>
                <div className="w3-light-grey">
                    <div className="w3-container w3-padding-small w3-dark-grey w3-center" style={{ width: '100%' }}>100%</div>
                </div>
            </div>
            <div className="w3-row w3-center w3-dark-grey w3-padding-16">
                <div className="w3-quarter w3-section">
                    <span className="w3-xlarge">140+</span><br />
                    Vendors
                </div>
                <div className="w3-quarter w3-section">
                    <span className="w3-xlarge">250+</span><br />
                    Projects Done
                </div>
                <div className="w3-quarter w3-section">
                    <span className="w3-xlarge">200+</span><br />
                    Happy Clients
                </div>
                <div className="w3-quarter w3-section">
                    <span className="w3-xlarge">400+</span><br />
                    Meetings
                </div>
            </div>
            <div className="bgimg-2 w3-display-container w3-opacity-min">
                <div className="w3-display-middle">
                    <span className="w3-xxlarge w3-text-white w3-wide">PORTFOLIO</span>
                </div>
            </div>
            <div className="w3-content w3-container w3-padding-64" id="portfolio">
                <h3 className="w3-center">MY WORK</h3>
                <p className="w3-center"><em>Here are some of my surgical instruments<br /> Click on the images to make them bigger</em></p><br />
                {
                    Array(state + 1).fill().map((_, i) => {
                        const classNames = ['3-row-padding', 'w3-center'];
                        if (state === i) {
                            classNames.push('w3-section')
                        }
                        return (
                            <div key={i} className={classNames.join(' ')}>
                                {
                                    Array(4).fill().map((_, j) => {
                                        return (
                                            <div key={j} className="w3-col m3">
                                                <img onClick={() => {
                                                    setModal(4 * i + j);
                                                }} src={`/portfolio/${4 * i + j}.jpg`} style={{ width: '100%', height: 300 }} className="w3-hover-opacity" />
                                            </div>
                                        )
                                    })
                                }
                                {state === i && state < 10 && <button onClick={() => {
                                    setState(state => state + 1);
                                }} className="w3-button w3-padding-large w3-light-grey" style={{ marginTop: 64 }}>LOAD MORE</button>}
                            </div>
                        )
                    })
                }
            </div>
            {modal > -1 && <div className="w3-modal w3-black w3-show">
                <span onClick={() => {
                    setModal(-1);
                }} className="w3-button w3-large w3-black w3-display-topright" title="Close Modal Image"><i className="fa fa-remove"></i></span>
                <div className="w3-modal-content w3-animate-zoom w3-center w3-transparent w3-padding-64">
                    <img src={`/portfolio/${modal}.jpg`} className="w3-image" />
                    <p id="caption" className="w3-opacity w3-large"></p>
                </div>
            </div>}
            <div className="bgimg-3 w3-display-container w3-opacity-min">
                <div className="w3-display-middle">
                    <span className="w3-xxlarge w3-text-white w3-wide">CONTACT</span>
                </div>
            </div>
            <div className="w3-content w3-container w3-padding-64" id="contact">
                <h3 className="w3-center">WANT TO CONTACT</h3>
                <p className="w3-center"><em>I'd love to listen from you!</em></p>

                <div className="w3-row w3-padding-32 w3-section">
                    <div className="w3-col m4 w3-container">
                        <img src="/w3images/map.jpeg" className="w3-image w3-round" style={{
                            width: '100%'
                        }} />
                    </div>
                    <div className="w3-col m8 w3-panel">
                        <div className="w3-large w3-margin-bottom">
                            <i className="fa fa-map-marker fa-fw w3-hover-text-black w3-xlarge w3-margin-right"></i> Mumbai, India<br />
                            <i className="fa fa-phone fa-fw w3-hover-text-black w3-xlarge w3-margin-right"></i> Phone: +91 9833383674<br />
                            <i className="fa fa-envelope fa-fw w3-hover-text-black w3-xlarge w3-margin-right"></i> Email: hemantdazzle7@gmail.com<br />
                        </div>
                        <p>Swing by for a cup of <i className="fa fa-coffee"></i>, or leave me a note:</p>
                        <form onSubmit={e => {
                            e.preventDefault();
                        }}>
                            <div className="w3-row-padding" style={{ margin: '0 -16px 8px -16px' }}>
                                <div className="w3-half">
                                    <input className="w3-input w3-border" type="text" placeholder="Name" required name="Name" />
                                </div>
                                <div className="w3-half">
                                    <input className="w3-input w3-border" type="text" placeholder="Email" required name="Email" />
                                </div>
                            </div>
                            <input className="w3-input w3-border" type="text" placeholder="Message" required name="Message" />
                            <button className="w3-button w3-black w3-right w3-section" type="submit">
                                <i className="fa fa-paper-plane"></i> SEND MESSAGE
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            <footer className="w3-center w3-black w3-padding-64 w3-opacity w3-hover-opacity-off">
                <a href="#home" className="w3-button w3-light-grey"><i className="fa fa-arrow-up w3-margin-right"></i>To the top</a>
                <div className="w3-xlarge w3-section">
                    <i className="fa fa-facebook-official w3-hover-opacity"></i>
                    <i className="fa fa-instagram w3-hover-opacity"></i>
                    <i className="fa fa-snapchat w3-hover-opacity"></i>
                    <i className="fa fa-pinterest-p w3-hover-opacity"></i>
                    <i className="fa fa-twitter w3-hover-opacity"></i>
                    <i className="fa fa-linkedin w3-hover-opacity"></i>
                </div>
                <p>Powered by <a href="https://www.w3schools.com/w3css/default.asp" title="W3.CSS" target="_blank" className="w3-hover-text-green">w3.css</a></p>
            </footer>
        </>
    )
}
const root = createRoot(document.getElementById("root"));
root.render(<App />);